import React from 'react';
import {Box, Container, Flex} from 'theme-ui';

import Layout from 'components/layout';
import SEO from 'components/seo';

const ImpressumPage = () => (
    <Layout>
        <SEO title="Impressum" />
        <Box as="section" id="impressum" sx={styles.impressum}>
            <Container sx={styles.impressum.container}>
                <Flex sx={styles.impressum.row}>
                    <Box sx={styles.impressum.col}>
                        <h1>Impressum</h1>
                        <h3>Confinio GmbH</h3>
                        <address>
                            Streitgasse 4<br/>
                            4051 Basel<br/>
                            E-Mail: kontakt(at)confinio.ch
                        </address>
                        <p>Geschäftsführer/gesetzlicher Vertreter: Dirk Strahberger, Rosemarie Schmidt</p>
                        <h3>Haftungsausschluss</h3>
                        <h5>Haftung für Inhalte</h5>
                        <p>Die Inhalte dieser Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen.</p>
                        <h5>Haftung für Links</h5>
                        <p>Dieses Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>
                        <h5>Urheberrecht</h5>
                        <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>
                        <h5>Datenschutz</h5>
                        <p>Die Nutzung dieser Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf diesen Seiten personenbezogene Daten (beispielsweise Name, Telefonnummer oder E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben. Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich. Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.</p>
                    </Box>
                </Flex>
            </Container>
        </Box>
        <Box sx={styles.layout}>

        </Box>
    </Layout>
);

export default ImpressumPage;

const styles = {
    impressum: {
        overflowX: 'hidden',
        paddingTop: ['100px', '120px'],
        paddingBottom: ['0px', null, '80px'],
        container: {
            maxWidth: ['100%', null, null, null, null, '1240px', '1440px'],
        },
        row: {
            flexWrap: 'wrap',
            display: 'flex',
            marginLeft: '-15px',
            marginRight: '-15px',
        },
        col: {
            pl: '15px',
            pr: '15px',
            flex: ['1 1 100%', null, '0 0 100%'],
        },
    },
};
